import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  upgradeSeatData: [],
  isLoading: false,
  hasData: false,
  error: {},
  isError: false
};
const upgradeSeatSlice = createSlice({
  name: "upgradeSeat",
  initialState,
  reducers: {
    upgradeSeatRequest: state => {
      state.isLoading = true;
    },
    upgradeSeatSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = {};
      state.upgradeSeatData = action.payload.data;
      state.isError = false;
    },
    upgradeSeatFailure: (state, action) => {
      var _action$payload, _state$error;
      state.isLoading = false;
      state.error = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data;
      state.upgradeSeatData = [];
      console.log(action);
      if (((_state$error = state.error) === null || _state$error === void 0 ? void 0 : _state$error.status) === 400) {
        state.isError = true;
      }
    },
    clearUpgradeSeatData: state => {
      state.upgradeSeatData = [];
    }
  }
});
export const {
  upgradeSeatRequest,
  upgradeSeatSuccess,
  upgradeSeatFailure,
  clearUpgradeSeatData
} = upgradeSeatSlice.actions;
export default upgradeSeatSlice.reducer;