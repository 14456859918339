import axios from "axios";
//process.env variable to be added
//apiconfig/ apiconstants to be added

//ToDo:This can be uncommented once the CMS supports all country lang codes.
// Now only handling english and thai
//const countryLanguageCodeContent: string = (i18n.language?.split('-')[1] + '/'+ i18n.language?.split('-')[0]) || 'th/en';
//const countryLanguageCode: string = i18n.language || 'en-th';
const countryLanguageCodeContent = 'th/en';
const countryLanguageCode = 'en-th';
const base_urls = {
  teamsite: process.env.REACT_APP_TG_TEAMSITE_URL + 'en-th',
  aemContentAuthored: process.env.REACT_APP_TG_AEM_BASE_URL + countryLanguageCodeContent,
  aemConfigured: process.env.REACT_APP_AEM_ROOT_DOMAIN + "content/dam/thaiairways/json/" + countryLanguageCode,
  aemServlet: process.env.REACT_APP_TG_AEM_SERVLET_URL
};
const cmsApi = axios.create();

// Request interceptor
cmsApi.interceptors.request.use(config => {
  // Modify the request config here (e.g., add headers, authentication tokens)
  config.baseURL = base_urls === null || base_urls === void 0 ? void 0 : base_urls[config === null || config === void 0 ? void 0 : config.engine];
  if ((config === null || config === void 0 ? void 0 : config.engine) == 'aemServlet') {
    config.url += '?' + countryLanguageCode;
  }
  return config;
}, error => {
  // Handle request errors here

  return Promise.reject(error);
});

// Response interceptor
cmsApi.interceptors.response.use(response => {
  // Modify the response data here (e.g., parse, transform)

  return response;
}, error => {
  // Handle response errors here

  return Promise.reject(error);
});
export default cmsApi;